import React, { useState, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { AppContext } from '../App';
import navListData from '../data/navListData';
import NavListItem from './NavListItem';
import './header.css';
import logoImage from '../images/logo.png';

function Header() {
  const [open, setOpen] = useState(false);
  const [navList, setNavList] = useState(navListData);
  const location = useLocation();
  const { scroll, library } = useContext(AppContext);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleNavOnClick = id => {
    const newNavList = navList.map(nav => {
      nav.active = false;
      if (nav._id === id) nav.active = true;
      return nav;
    });

    setNavList(newNavList);
  };

  return (
    <header
      className={`header ${open ? 'active' : undefined} ${
        scroll && scroll > 100 ? 'scrolled' : undefined
      }`}
    >
      {/* <a
        href="/"
        className="logo"
        style={
          location.pathname === '/'
            ? { color: '#ffffff' }
            : { color: '#ffffff' }
        }
      > */}
<img src={logoImage} alt="Vehicles" style={{ width: '60px' }} />
        {/* </a> */}
      <div>
        <Link className="like" to="/library">
          <i className="bi bi-heart-fill"></i>
          <span className="likeNumbers">{library.length}</span>
        </Link>
        {open ? (
          <a className="menu" onClick={handleToggleMenu}>
            <i className="bi bi-x"></i>
          </a>
        ) : (
          <a className="menu" onClick={handleToggleMenu}>
            <i className="bi bi-list"></i>
          </a>
        )}
      </div>

      <ul className={`nav ${open ? 'active' : null}`}>
        {navList.map(nav => (
          <NavListItem key={nav._id} nav={nav} navOnClick={handleNavOnClick} />
        ))}
      </ul>
    </header>
  );
}

export default Header;
