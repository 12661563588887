import React from 'react';
import { Link } from 'react-router-dom';
import './navListItem.css';


function NavListItem({ nav, navOnClick }) {
  return (
    <li>
      <Link
        to={nav.link}
        onClick={() => navOnClick(nav._id)}
        className={`${nav.active ? 'active' : undefined}`}
      >
        {nav.name === 'home' ? <i className="bi bi-house-door"></i> : nav.name}
      </Link>
    </li>
  );
}

export default NavListItem;




// import React from 'react';
// import { Link } from 'react-router-dom';
// import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn, FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';
// import './navListItem.css';

// function NavListItem({ nav, navOnClick }) {
//   // Helper function to determine if the nav item is an external link
//   const isGroupedIcon = (name) => {
//     return ['appstore', 'googleplay', 'facebook', 'instagram', 'youtube', 'linkedin'].includes(name);
//   };

//   const renderIcon = (name) => {
//     switch (name) {
//       case 'home':
//         return <i className="bi bi-house-door"></i>;
//       case 'facebook':
//         return <FaFacebookF />;
//       case 'instagram':
//         return <FaInstagram />;
//       case 'youtube':
//         return <FaYoutube />;
//       case 'linkedin':
//         return <FaLinkedinIn />;
//       case 'appstore':
//         return <FaAppStoreIos />;
//       case 'googleplay':
//         return <FaGooglePlay />;
//       default:
//         return name;
//     }
//   };

//   const isExternalLink = isGroupedIcon(nav.name);

//   return (
//     <li className={isExternalLink ? 'grouped-icon' : ''}>
//       {isExternalLink ? (
//         <a href={nav.link} target="_blank" rel="noopener noreferrer" onClick={() => navOnClick(nav._id)}>
//           {renderIcon(nav.name)}
//         </a>
//       ) : (
//         <Link to={nav.link} onClick={() => navOnClick(nav._id)} className={`${nav.active ? 'active' : ''}`}>
//           {renderIcon(nav.name)}
//         </Link>
//       )}
//     </li>
//   );
// }

// export default NavListItem;
