// import Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
// import Bootstrap Icons
import 'bootstrap-icons/font/bootstrap-icons.css';
// import Boxicons
import 'boxicons/css/boxicons.min.css';
// import react-router
import { Routes, Route } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Banner from './pages/Banner';
import Vehicles from './pages/Vehicles';
import VehicleDetails from './pages/VehicleDetails';
import AppDownload from './pages/Download';
import Contact from './pages/Contact';
import Library from './pages/Library';

export const AppContext = React.createContext();

function App() {
  const [library, setLibrary] = useState([]);
  const [data, setData] = useState([]);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY);
    });
    return () => {
      window.removeEventListener('scroll', () => {
        setScroll(window.scrollY);
      });
    };
  }, [scroll]);

  const fetchData = () => {
    // fetch('http://localhost:3000/api/vehiclesData.json')
    fetch('/api/vehiclesData.json')
      .then(res => res.json())
      .then(data => {
        setData(data);
      })
      .catch(e => console.log(e.message));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <AppContext.Provider
        value={{ data, setData, scroll, library, setLibrary }}
      >
        <Header />
        <Routes>
          <Route exact path="/" element={<Banner />} />
          <Route exact path="/vehicles" element={<Vehicles />} />
          <Route exact path="/vehicles/:id" element={<VehicleDetails />} />
          <Route exact path="/library" element={<Library />} />
          <Route exact path="/download" element={<AppDownload />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </AppContext.Provider>
    </>
  );
}

export default App;
