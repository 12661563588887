import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './vehicleDetails.css';
import IconBox from '../components/IconBox';

function VehicleDetails() {
  const [car, setCar] = useState({});
  const { id } = useParams();
  const index = parseInt(id) - 1;

  const fetchData = () => {
    // fetch('http://localhost:3000/api/vehiclesData.json')
    fetch('/api/vehiclesData.json')
      .then(res => res.json())
      .then(data => {
        setCar(data[index]);
      })
      .catch(e => console.log('Error fetching vehicle data:', e.message));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="vehicle-details" className="page vehicle-details">
      <img src={car.pageBgImg} alt={`${car.make} ${car.model}`} className="img-fluid page-img" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 p-0 details-left">
            <img
              src={car.bannerImg}
              alt={`${car.make} ${car.model}`}
              className="img-fluid details-img-left"
            />
            <span className="price">
              ${car.price ? car.price.toLocaleString('en-US') : 'Contact for pricing'}<span> per day</span>
            </span>
            <a href="#" className="contact-seller">
              <i className="bi bi-telephone-fill"></i> Book Now
            </a>
          </div>
          <div className="col-lg-4 p-0 details-middle">
            <div className="content">
              <h2>{car.model} Overview</h2>
              <IconBox
                icon="bi bi-speedometer2"
                title="High Performance"
                description={`Experience exhilarating performance with the ${car.model}, designed to deliver top speeds with precision handling.`}
              />
              <IconBox
                icon="bi bi-stars"
                title="Luxury & Comfort"
                description={`Enjoy unparalleled luxury and comfort with every rental, ensuring a premium experience in the ${car.model}.`}
              />
              <IconBox
                icon="bi bi-camera-reels"
                title="Advanced Features"
                description={`Equipped with state-of-the-art features to enhance your driving experience and safety on the road.`}
              />
              <IconBox
                icon="bi bi-trophy-fill"
                title="Award Winning"
                description={`Drive an award-winning vehicle renowned for its exceptional design and engineering.`}
              />
            </div>
            <img
              src={car.leftImg}
              alt={`${car.make} side view`}
              className="img-fluid details-img-middle"
            />
          </div>
          <div className="col-lg-4 p-0 details-right">
            <img
              src={car.rightImg}
              alt={`${car.make} ${car.model} right side`}
              className="img-fluid details-img-right"
            />
            <div className="details-description">
              <h4>Why Choose the {car.model}?</h4>
              <p>{car.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleDetails;
