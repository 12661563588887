const navListData = [

  {
    _id: 1,
    link: '/',
    name: 'home',
    active: true,
  },
  {
    _id: 2,
    link: '/vehicles',
    name: 'Vehicles',
    active: false,
  },
  {
    _id: 3,
    link: '/download',
    name: 'App Download',
    active: false,
  },
  {
    _id: 4,
    link: '/contact',
    name: 'Contact',
    active: false,
  }
  
];

export default navListData;


// {
//   _id: 'appstore',
//   link: 'https://www.apple.com/app-store/',
//   name: 'appstore',
//   active: false,
// },
// {
//   _id: 'googleplay',
//   link: 'https://play.google.com/store',
//   name: 'googleplay',
//   active: false,
// },

// {
//   _id: 'facebook',
//   link: 'https://facebook.com',
//   name: 'facebook',
//   active: false,
// },
// {
//   _id: 'instagram',
//   link: 'https://instagram.com',
//   name: 'instagram',
//   active: false,
// },
// {
//   _id: 'youtube',
//   link: 'https://youtube.com',
//   name: 'youtube',
//   active: false,
// },
// {
//   _id: 'linkedin',
//   link: 'https://linkedin.com',
//   name: 'linkedin',
//   active: false,
// },