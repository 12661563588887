import React from 'react';
import './slideBtn.css';
import { Link } from 'react-router-dom';

function SlideBtn({ slideChange }) {
  return (
    <Link className="slide-btn" onClick={slideChange}>
      <i className="bi bi-play-fill"></i>
    </Link>
  );
}

export default SlideBtn;
