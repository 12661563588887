import React, { useState } from 'react';
import './contact.css';
import bgImg from '../images/mclaren-1.jpg';
import PageTitle from '../components/PageTitle';

function Contact() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const sendMessage = (e) => {
    e.preventDefault();
    if (input.trim()) {
      setMessages([...messages, { text: input, fromUser: true }]);
      // Simulate a reply
      setTimeout(() => {
        setMessages(messages => [...messages, { text: "Thanks for your message! We're on it.", fromUser: false }]);
      }, 1000);
    }
    setInput('');
  };

  return (
    <div id="contact" className="page contact">
      <img src={bgImg} alt="" className="img-fluid page-img" />
      <div className="container">
        <PageTitle
          title="Chat with Us"
          subtitle="We are here to help you"
        />
        <div className="chat-container" style={{ maxWidth: '600px', margin: 'auto', padding: '20px', backgroundColor: '#f7f7f7', borderRadius: '8px' }}>
          <div className="messages">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.fromUser ? 'user-message' : 'bot-message'}`}>
                {msg.text}
              </div>
            ))}
          </div>
          <form onSubmit={sendMessage} className="message-form">
            <input
              type="text"
              value={input}
              onChange={e => setInput(e.target.value)}
              className="message-input"
              placeholder="Type your message here..."
            />
            <button type="submit" className="send-button">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;




// import React from 'react';
// import './contact.css';
// import bgImg from '../images/mclaren-1.jpg';
// import PageTitle from '../components/PageTitle';

// function Contact() {
//   return (
//     <div id="contact" className="page contact">
//       <img src={bgImg} alt="" className="img-fluid page-img" />
//       <div className="container">
//         <PageTitle
//           title="Contact Us"
//           subtitle="We are happy to hear from you"
//         />
//         <div>
//           <iframe
//             style={{ border: 0, width: '100%', height: '270px' }}
//             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d453481.7409355457!2d152.6635633518984!3d-27.381145856489333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91579aac93d233%3A0x402a35af3deaf40!2sBrisbane%20QLD!5e0!3m2!1sen!2sau!4v1696121093583!5m2!1sen!2sau"
//             frameBorder="0"
//             allowFullScreen
//             loading="lazy"
//           ></iframe>
//           <div className="row mt-5">
//             <div className="col-lg-4">
//               <div className="info">
//                 <div className="address">
//                   <i className="bi bi-geo-alt"></i>
//                   <h4>Location:</h4>
//                   <p>Street Name, City Name, State 123456</p>
//                 </div>

//                 <div className="email">
//                   <i className="bi bi-envelope"></i>
//                   <h4>Email:</h4>
//                   <p>info@example.com</p>
//                 </div>

//                 <div className="phone">
//                   <i className="bi bi-phone"></i>
//                   <h4>Call:</h4>
//                   <p>+1 2345 6789 00</p>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-8 mt-5 mt-lg-0">
//               <form className="contact-form">
//                 <div className="row">
//                   <div className="col-md-6 form-group">
//                     <input
//                       type="text"
//                       name="name"
//                       className="form-control"
//                       id="name"
//                       placeholder="Your Name"
//                     />
//                   </div>
//                   <div className="col-md-6 form-group mt-3 mt-md-0">
//                     <input
//                       type="email"
//                       className="form-control"
//                       name="email"
//                       id="email"
//                       placeholder="Your Email"
//                     />
//                   </div>
//                 </div>
//                 <div className="form-group mt-3">
//                   <input
//                     type="text"
//                     className="form-control"
//                     name="subject"
//                     id="subject"
//                     placeholder="Subject"
//                   />
//                 </div>
//                 <div className="form-group mt-3">
//                   <textarea
//                     className="form-control"
//                     name="message"
//                     rows="5"
//                     placeholder="Message"
//                   ></textarea>
//                 </div>
//                 <div className="text-center mt-3">
//                   <button type="submit">Send Message</button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Contact;
