import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { FaApple, FaAndroid } from 'react-icons/fa';
import './AppDownload.css'; // Ensure Bootstrap CSS is included
import PageTitle from '../components/PageTitle';

function AppDownload() {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div id="app-download" className="page app-download">
      <Container className="text-center">
        <PageTitle title="Download Our App" subtitle="Get the app for your device" />
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={4} className="mb-3">
            <Button variant="outline-dark" size="lg" className="w-100 app-button" onClick={handleShow}>
              <FaApple className="me-2" size={30} />Download on the App Store
            </Button>
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-3">
            <Button variant="outline-dark" size="lg" className="w-100 app-button" onClick={handleShow}>
              <FaAndroid className="me-2" size={30} />Get it on Google Play
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Coming Soon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Our app is almost ready! Stay tuned for more updates.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AppDownload;
