import React, { useContext, useState } from 'react';
import './banner.css';
import { AppContext } from '../App';
import CircleBar from '../components/CircleBar';
import SlideBtn from '../components/SlideBtn';
import { Link } from 'react-router-dom';

function Banner() {
  const { data: cars, setData: setCars } = useContext(AppContext);
  const [video, setVideo] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleSlideChange = ind => {
    if (ind >= 4) {
      ind = -1;
    }
    setCars(
      cars.map((car, index) => {
        car.active = false;
        if (index === ind + 1) {
          car.active = true;
        }
        return car;
      })
    );
  };

  const toggleVideo = () => {
    setVideo(!video);
  };


  const handleLoadedData = () => {
    setLoading(false);
  };
  return (
    <div className="banner">
      {cars &&
        cars.length > 0 &&
        cars.slice(0, 5).map((car, index) => (
          <div className={`slide ${car.active ? "active" : ""}`} key={car._id}>
            <div className="container-fluid">
              <div className="row banner-top">
                <div className="col-lg-4 p-0 banner-top-left">
                  <div className="banner-title">
                    <h1>Get Your Dream {car.make}</h1>
                  </div>
                  <span className="slide-number">0{car._id}</span>
                  <SlideBtn slideChange={() => handleSlideChange(index)} />
                </div>
                <div className="col-lg-8 p-0 banner-top-right">
                  <div className="banner-img">
                    <img
                      src={car.bannerImg}
                      alt=""
                      className={`img-fluid ${video ? "" : "active"}`}
                    />
                    {loading ? (
                      <div className="loading-indicator">Loading...</div>
                    ) : null}
                    <video
                      className={`banner-video ${video ? "active" : ""}`}
                      src={car.video}
                      autoPlay
                      loop
                      muted
                      onCanPlayThrough={handleLoadedData}
                    ></video>
                    <div className="car-brief">
                      <div className="car-intro">
                        <CircleBar
                          number={car.power}
                          name="Power"
                          color="#f82249"
                        />
                        <CircleBar
                          number={car.engine}
                          name="Engine"
                          color="#fe5d26"
                        />
                        <CircleBar
                          number={car.new}
                          name="New"
                          color="#00df22"
                        />
                      </div>
                      <ul className="car-nav">
                        <li>
                          <Link to={`/vehicles/${car._id}`}>
                            Details <i className="bi bi-arrow-right-short"></i>
                          </Link>
                        </li>
                        <li>
                          <a href="#" onClick={toggleVideo}>
                            {video ? "Image" : "Video"}{" "}
                            <i className="bi bi-arrow-right-short"></i>
                          </a>
                        </li>
                        <li>
                          <Link to="/vehicles">
                            More Vehicles{" "}
                            <i className="bi bi-arrow-right-short"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row banner-bottom">
                <div className="col-lg-4 p-0">
                  <div className="banner-img">
                    <img src={car.leftImg} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-5 p-0">
                  <div className="car-description">
                    <div>
                      <h2>{car.title}</h2>
                    </div>
                    <div className="car-features">
                      <p>{car.description}</p>
                      <Link
                        to={`/vehicles/${car._id}`}
                        className="detail-link text-center"
                      >
                        Explore <i className="bi bi-arrow-right-short"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 p-0">
                  <img src={car.rightImg} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Banner;
